import { Alert, Box, Divider, Grid, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next";
import FadeIn from "react-fade-in/lib/FadeIn";
import { useEffect, useState } from "react";
import { ProductPatterns } from "../../components/step-3/product-patterns.component";
import { useDispatch, useSelector } from "react-redux";
import { VendorState } from "../../../../../redux/vendor/rootReducer";
import { ProductImagesPicker } from "../../components/step-3/product-images-picker.component";
import { EditProduct } from "../../models/edit-product.model";
import * as _ from "lodash"
import { APPLICATION_TYPE } from "../../../my-products/models/product.model";

interface ProductVisualizationProps {
    editProduct: EditProduct
    setEditProduct,
    activeStep: number
}

export const ProductVisualizationForm = (props: ProductVisualizationProps) => {
    const { setEditProduct, editProduct } = props
    const [selectedTab, setSelectedTab] = useState(0)
    const [t] = useTranslation()
    const [tabs, setTabs] = useState([])
    const { editProductStepToValidate } = useSelector((state: VendorState) => state.products);

    useEffect(() => {
        if (editProduct.colors.length === 0) {
            setEditProduct(_.cloneDeep(props.editProduct.setColors(["default"])))
        }
        setTabs([t("PAGES.NEW_PRODUCT.VISUALIZATION.PATTERN")])
    }, [editProduct.colors])

    const isValidPattern = () => {
        return editProductStepToValidate === props.activeStep && (!editProduct.patterns || editProduct.patterns.length === 0)
    }

    return <FadeIn>
        <Box display={"flex"} flexWrap={"nowrap"} overflow={"hidden"} width={"100%"}>
            {tabs.map((tab, index) => (<Typography fontSize={14} flexWrap={"wrap"} key={index}
                bgcolor={selectedTab === index ? "primary.light" : "primary.main"} padding={2}
                border={selectedTab !== index ? "1px solid black" : "none"}
                borderColor={"primary.dark"}
                sx={{ borderTopRightRadius: "20px", cursor: "pointer" }}
                onClick={() => setSelectedTab(index)}
                color={"primary.text"} letterSpacing={0} fontWeight={"bold"} textTransform={'capitalize'}>{tab}</Typography>))}
        </Box>
        <Divider />
        <Box bgcolor={"primary.light"} padding={2} sx={{ borderBottomLeftRadius: "18px", borderBottomRightRadius: "18px" }} position={"relative"} display={"flex"} flexDirection={"column"}>
            {selectedTab === 0 && <ProductPatterns {...props} />}
        </Box>
        {isValidPattern() && <Alert severity="error" sx={{ marginTop: 2 }}>{t('PAGES.NEW_PRODUCT.GENERAL_INFO.ERRORS.NO_PATTERN_SELECTED')}</Alert>}
    </FadeIn>
}
