import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import axios from "axios";

export function ApiMonitorPage() {
    const [status, setStatus] = useState<string>("loading");
    const [isRestarting, setIsRestarting] = useState<boolean>(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const fetchStatus = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/api/status`);
            setStatus(response.data.status);
        } catch (error) {
            setStatus("down");
        }
    };

    const restartApi = async () => {
        setIsRestarting(true);
        try {
            await axios.post(`${API_BASE_URL}/api/restart`);
            setTimeout(fetchStatus, 10000);
        } catch (error) {
            console.error("Failed to restart API");
        }
        setIsRestarting(false);
    };

    useEffect(() => {
        fetchStatus();
    }, []);

    return (
        <Box p={3} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h5">API Monitor</Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
                API Status: 
                {status === "loading" ? <CircularProgress size={20} sx={{ ml: 1 }} /> : 
                status === "running" ? 
                <span style={{ color: "green" }}> Running ✅</span> : 
                <span style={{ color: "red" }}> Down ❌</span>}
            </Typography>

            <Button 
                variant="contained" 
                color="secondary"
                onClick={restartApi}
                sx={{ mt: 3 }}
            >
                {isRestarting ? <CircularProgress size={20} sx={{ mr: 1 }} /> : "Restart API"}
            </Button>
        </Box>
    );
}
