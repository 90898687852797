import { useState } from 'react';
import { Alert, Box, Divider, Grid, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch } from 'react-redux';
import { theme } from "../../../theme"
import { MainHeight, MainHeightMobile, MainWidth, MainWidthMobile } from '../../../assets/constants.css';

import statPageImage from "../../../assets/blurred_bg.webp"
import logo from "../../../assets/logo_blue.webp"
import { useAuthContext } from '../../../common/hooks/use-auth-context.hook';

const LoginPage = () => {
    const { isAuthenticated, login, logout, me } = useAuthContext();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch()
    const isMobileAndDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [mainHeight] = useState(isMobileAndDown ? MainHeightMobile : MainHeight)
    const [mainWidth] = useState(isMobileAndDown ? MainWidthMobile : MainWidth)
    const [isBadLogin, setIsBadLogin] = useState(false)

    const signIn = async (email, password) => {
        try {
            await login(email, password)
            if (!isAuthenticated) {
                setIsBadLogin(true)
            }
        } catch (err) {
            console.error(err);
            setIsBadLogin(true)
        }
    };

    const handleKeyPress = (event) => {
        if (event.keyCode === 13) {
            signIn(email, password)
        }
    }

    return (
        <>
            <style>
                {`
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0px 1000px white inset !important;
            background-color: transparent !important;
          }
        `}
            </style>

            <Box>
                <Grid container style={{ height: mainHeight, borderRadius: 8 }} bgcolor={"primary.main"}>
                    <Grid item xs={12} md={5}>
                        <Box bgcolor={"white"} position={"relative"} height={"100%"} display={"flex"} flexDirection={"column"} alignItems="center" justifyContent={"space-between"} borderRadius={4}>
                            <Box display={"flex"} flexDirection={"column"} height={"100%"} width={"75%"} marginTop={15}>
                                <img src={logo} style={{ width: "150px" }} alt="Logo" />
                                <Typography variant='caption' textTransform={"capitalize"} marginTop={10} fontWeight={"bolder"} color={"#2d3331"} sx={{ fontSize: "18pt", letterSpacing: -0.5 }} lineHeight={1.2}>
                                    Welcome back
                                </Typography>
                                <Typography variant='caption' sx={{ fontSize: "10pt", letterSpacing: -0.5, color: "#9ea7ab" }} lineHeight={1} marginTop={1}>
                                    Log in with your email and password
                                </Typography>

                                <Divider sx={{ marginBlock: 4, borderColor: '#f0f0f0' }} />

                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Email"
                                    value={email}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailOutlinedIcon sx={{ color: "#71777a", fontSize: "16px" }} />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            backgroundColor: "white",
                                            borderRadius: "12px",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#E0E0E0",
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#BDBDBD",
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "rgba(179, 191, 201, 0.2)",
                                            },
                                            "& input": {
                                                fontSize: "12px",
                                                padding: "10px",
                                            },
                                            "&:-webkit-autofill": {
                                                WebkitBoxShadow: "0 0 0 100px white inset !important",
                                                backgroundColor: "transparent !important",
                                            },
                                        },
                                    }}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                                <TextField
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Password"
                                    value={password}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <LockOutlinedIcon sx={{ color: "#71777a", fontSize: "16px" }} />
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            marginTop: 2,
                                            backgroundColor: "white",
                                            borderRadius: "12px",
                                            "& .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#E0E0E0",
                                            },
                                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "#BDBDBD",
                                            },
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                borderColor: "rgba(179, 191, 201, 0.2)",
                                            },
                                            "& input": {
                                                fontSize: "12px",
                                                padding: "10px",
                                            },
                                            "&:-webkit-autofill": {
                                                WebkitBoxShadow: "0 0 0 100px white inset !important",
                                                backgroundColor: "transparent !important",
                                            },
                                        },
                                    }}
                                    onChange={(e) => setPassword(e.target.value)}
                                    onKeyDown={handleKeyPress}
                                />


                                <Box
                                    onClick={() => signIn(email, password)}
                                    marginTop={4} padding={1.5} color={"primary.main"} borderRadius={2} textAlign={"center"}
                                    sx={{ cursor: "pointer", background: "#10305e" }}
                                >
                                    <Typography variant='caption' sx={{ fontSize: "11pt", letterSpacing: -0.5, marginTop: 1 }}>Log in</Typography>
                                </Box>

                                {isBadLogin && (
                                    <Typography variant='caption' textAlign={"center"} sx={{ fontSize: "10pt", letterSpacing: -0.5, color: "#c60101" }} lineHeight={1} marginTop={2}>
                                        Please verify your email or password and try again.
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        md={6.5}
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'block' },
                            '@media (max-width: 959px)': {
                                display: 'none !important',
                            },
                        }}
                        bgcolor={"primary.light"}
                        height={"90%"}
                        style={{
                            margin: "auto",
                            borderRadius: "14px",
                            backgroundImage: `url(${statPageImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <Typography
                            width={"70%"}
                            variant='caption'
                            color={"white"}
                            padding={1}
                            borderRadius={2}
                            sx={{ fontSize: "28pt", letterSpacing: -0.5 }}
                            lineHeight={1.2}
                        >
                            The simplest way to manage your visualizer
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default LoginPage;
